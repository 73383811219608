import { Helmet } from "react-helmet";
import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import { WScalling } from "./Apicalling";
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { Formik, Field, ErrorMessage, Form } from 'formik';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Switch,
} from "@mui/material";


import {
  baseApiUrl,
  GetobjFields,
  GetobjColumn,
  GetobjList,
  Createobj,
  GetObjEditField,
  ObjUpdate,
  HistoryListApi,
  GetEntryField,
  createEntry,
  updateEntry,
  EditApi,
  objectTypeList
} from "./const";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import 'dayjs/locale/en-gb';
dayjs.locale('en-gb');

export let userData = [];
export let addFiledList = [];
export let data = '';
export let errorcode = 0;
var filteredObjects = [];
let filterList;
let searchObjectType = null;
let searchObjectName = null;
let searchObjectExpire = null;

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});


const Home = () => {
  const [isTyping, setIsTyping] = useState(false);
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [Addentrydialog, setAddentrydialog] = useState(false);
  const [companyid] = useState({ company_id: userDetail.company_id });
  const [objFieldList, setobjFieldList] = useState(() => []);
  const [MhFieldList, setMhFieldList] = useState(() => []);
  const [MhTaskList, setMhTaskList] = useState(() => []);
  const [HistoryList, setHistoryList] = useState(() => []);
  const [objColumnList, setobjColumnList] = useState(() => []);
  const [objTableList, setTableList] = useState(() => []);
  const [updatedData, setupdatedData] = useState(() => []);

  const [filterObjList, setfilterObjList] = useState(() => []);
  const [FieldlList, setFieldlList] = useState(() => []);
  const [TaskList, setTaskList] = useState(() => []);
  const [datePickerStates, setDatePickerStates] = useState([]); // State array for DatePicker visibility

  const [Name, setName] = useState('');
  let [addFields, setaddFields] = useState(() => []);
  const [ObjectIcon, SetObjectIcon] = useState('');
  let [EditMode, setEditMode] = useState(false);
  let [EditHsMode, setEditHsMode] = useState(false);

  let [SearchObjType, setSearchObjType] = useState('');
  let [SearchObjinput, setSearchObjinput] = useState('');

  let [Rowid, setRowid] = useState('');
  let [objectId, setObjectId] = useState('');
  let [Hstitle, setHstitle] = useState('');
  let [HsSubtitle, setHsSubtitle] = useState('');

  // Entry values...
  let [Currentdate] = useState(`${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`);
  let [ShowMslist, setShowMslist] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color] = useState("#2c82d8");
  const [addObjfuc, setObjfuc] = useState(false);
  const [addentryfuc, setaddentryfuc] = useState(false);
  const [expireobj, setexpireobj] = useState(false);
  const [rolename] = useState(userDetail.role_name === "User" ? true : null);
  const { t } = useTranslation();

  let [pagetitle] = useState(() => t('Lang.Form360') + ' - ' + t('Lang.Overview'));

  let [max_object] = useState(userDetail.max_objects);
  let [objstatus, setobjstatus] = useState(true);

  let [pagstate, setpagstate] = useState(false);
  let [mhpagstate, setmhpagstate] = useState(false);


  const [mhpagination, setMhPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const [forminitialval, setforminitialval] = useState({ name: '', objFieldList, status: '0' });

  const [formMHistory, setformMHistory] = useState({
    type: '',
    title: '', workshop: '',
    date_of_creation: '', time_of_creation: '',
    engine_hours: '', vehicle_mileage: '',
    popupnotifi: '1', Email: '0', MhFieldList, MhTaskList
  })

  const [sorting, setSorting] = useState([]);
  const [HSsorting, setHSSorting] = useState([]);


  const HistoryColumns = useMemo(
    () => [
      {
        accessorKey: "service_date",
        header: t('Lang.Service date'),
        enableColumnOrdering: false,
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <label>{(renderedCellValue === '01.01.1970' || renderedCellValue === '' || renderedCellValue === null) ? '' : renderedCellValue.split("-").reverse().join(".")}</label>
          </div>
        ),
        // Custom sorting logic for service_date
        sortingFn: (rowA, rowB) => {
          const dateA = rowA.original.service_date;
          const dateB = rowB.original.service_date;
      
          // Handle empty/null values by pushing them to the bottom
          if (!dateA) return 1;
          if (!dateB) return -1;
      
          // Convert the 'dd-mm-yyyy' format to 'yyyy-mm-dd' for comparison
          const [dayA, monthA, yearA] = dateA.split(".");
          const [dayB, monthB, yearB] = dateB.split(".");
      
          // Construct dates in 'yyyy-mm-dd' format for comparison
          const formattedDateA = new Date(`${yearA}-${monthA}-${dayA}`);
          const formattedDateB = new Date(`${yearB}-${monthB}-${dayB}`);
      
          return formattedDateA - formattedDateB;
        },
      },
      {
        accessorKey: "type",
        header: t('Lang.Type'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: "title",
        header: t('Lang.Title'),
        size: 150
      },
      {
        accessorKey: "cost",
        header: t('Lang.Cost'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: "description",
        header: t('Lang.Description'),
        size: 140,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "workshop",
        header: t('Lang.Workshop'),
        size: 140,
        enableColumnOrdering: false,

      },
      {
        accessorKey: "created_on",
        header: t('Lang.Created on'),
        size: 140,
        enableColumnOrdering: false,
        Cell : ({ renderedCellValue, row }) => (
          <div>
            <label className="w-100">{formatDateEurope(row.original.created_on)}</label>
          </div>
        )

      },
      {
        accessorKey: "created_by",
        header: t('Lang.Created by'),
        size: 80,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "updated_on",
        header: t('Lang.Updated on'),
        size: 80,
        enableColumnOrdering: false,
        Cell : ({ renderedCellValue, row }) => (
          <div>
            <label className="w-100">{formatDateEurope(row.original.updated_on)}</label>
          </div>
        )	
      },
    ]
  );


  useEffect(() => {
    // Chanage pagination of object table listing

    const storagepagi = JSON.parse(localStorage.getItem('Obj_Pagination'))
    if (storagepagi === null) {
      localStorage.setItem('Obj_Pagination', JSON.stringify(pagination));
    }
    else {
      if (pagstate) {
        localStorage.setItem('Obj_Pagination', JSON.stringify(pagination));
        setpagstate(false)
      }
      else {
        setPagination(storagepagi)
      }
    }

    // Chanage pagination of Maintanance history table listing
    const mhstoragepagi = JSON.parse(localStorage.getItem('MH_Pagination'))

    if (mhstoragepagi === null) {
      localStorage.setItem('MH_Pagination', JSON.stringify(mhpagination));
    }
    else {
      if (mhpagstate) {
        localStorage.setItem('MH_Pagination', JSON.stringify(mhpagination));
        setmhpagstate(false)
      }
      else {
        setMhPagination(mhstoragepagi)
      }
    }

    if (objColumnList.length === 0) {
      // All Field Api Calling (Show All Field to Table Listing)
      WScalling(baseApiUrl + GetobjColumn, "post", companyid, userDetail.token).then((response) => {
        if (response.data.length > 1) {
          for (let i = 0; i < response.data.length; i++) {
            const field = response.data[i];

            if (response.data[i].header === 'Object') {
              field.header = t('Lang.' + field.header);
            }
            if (response.data[i].header === 'Status') {
              field.header = t('Lang.' + field.header);
            }
          }
          setColumnList(response.data);
        }
      });
    }
  }, [pagination.pageSize, mhpagination.pageSize]);


  const setUpdatedData = (id, val, isChecked, index) => {

    updatedData[id] = addFields[index].value
    const checkoption = updatedData[id].split(',')

    if (isChecked) {
      if (updatedData[id] === '') {
        updatedData[id] = val;
      }
      else {
        updatedData[id] = updatedData[id] + ',' + val;
      }
    }
    else {
      const index = checkoption.indexOf(val);
      if (index > -1) {
        checkoption.splice(index, 1);
      }
      updatedData[id] = checkoption.join().toString()
    }

    const arrData = [...addFields];
    arrData[index] = {
      ...arrData[index],
      id: id,
      value: updatedData[id]
    }
    setaddFields(arrData)
  }


  const setColumnList = (data) => {

    for (let i = 0; i < data.length; i++) {
      const field = data[i];

      if (data[i].accessorKey === 'status') {
        field.size = 20;
        field.Cell = ({ renderedCellValue, row }) => (
          <span className={renderedCellValue === "1" ? "active" : "inactive"}
          ></span>
        )
      }
      else if (data[i].accessorKey === 'last_update') {
        data[i].header = t('Lang.' + data[i].header)
        field.Cell = ({ renderedCellValue, row }) => (
          <div>
              <label className="w-100">{formatDateEurope(row.original.last_update)}</label>
          </div>
        )
      }

      else if (data[i].accessorKey === 'object_type') {
        data[i].header = t('Lang.' + data[i].header)

        field.Cell = ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <div className="radio-common">
              <span className={"img_common_line mx-auto " + row.original.object_type}> </span>
            </div>
            <label className="text-center w-100">{t('Lang.' + row.original.object_type)}</label>
          </div>
        )
      }
      else if (data[i].type === 'date') {
        const name = data[i].accessorKey;
        const associate = data[i].associate;
      
        field.Cell = ({ renderedCellValue, row }) => {
          return (
            <>
              <div>
                {
                  <label className={
                    row.original[name] === undefined || row.original[name] === null ? 'black' :

                      row.original[name].split(".")[2] + '-' + row.original[name].split(".")[1] + '-' + row.original[name].split(".")[0] === Currentdate
                        ?
                        'blackclass' :
                        associate === 'Entries' && new Date(row.original[name].split(".")[2] + '-' + row.original[name].split(".")[1] + '-' + row.original[name].split(".")[0]).getTime() < new Date(Currentdate).getTime()
                          ?
                          'text_danger' :
                          'blackclass'
                  } >
                    { 
                      (row.original[name] === '01.01.1970' || row.original[name] === '1970-01-01' || row.original[name] === '' || row.original[name] === null || typeof row.original[name] !== 'string') ? '' : row.original[name].split("-")[2] + '.' + row.original[name].split("-")[1] + '.' + row.original[name].split("-")[0]
                    }
                  </label >
                }
              </div >
            </>
          )
        }
      } 
    }

    // Show Cloumn list
    setobjColumnList(data);

    WScalling(baseApiUrl + GetobjList, "post", companyid, userDetail.token).then((response) => {
      filterList = filteredObjects = response.data
      checkmaxobj(response.data.length)
      setfilterObjList(response.data)
      setTableList(response.data)
    });
  }


  const checkmaxobj = (objectcount) => {

    if (userDetail.id === 1) {
      setobjstatus(false)
    }
    else {
      if (objectcount >= max_object) {
        setobjstatus(true)
      }
      else {
        setobjstatus(false)
      }
    }
  }


  const addfieldsApi = () => {
    const objFields = []

    WScalling(baseApiUrl + GetobjFields, "post", companyid, userDetail.token).then((response) => {

      for (let i = 0; i < response.data.length; i++) {
        response.data[i].value = ""
        objFields.push(response.data[i])
      }

      forminitialval.name = ''
      forminitialval.objFieldList = objFields
      forminitialval.status = '0'
      setCreateModalOpen(true)
    });
  }

  const getEditFieldList = (rowid) => {
    const objFields = []

    WScalling(baseApiUrl + GetObjEditField + rowid, "get", "", userDetail.token).then((response) => {
      for (let i = 0; i < response.data.fields.length; i++) {

        if (response.data.fields[i].type === "checkbox") {
          if (response.data.fields[i].value === "") {
            response.data.fields[i].value = []
          }
          else {
            response.data.fields[i].value = response.data.fields[i].value.split(",")
          }
        }
        objFields.push(response.data.fields[i])
      }
      forminitialval.name = response.data.value
      forminitialval.objFieldList = objFields
      forminitialval.status = response.data.status

      setEditMode(true)
      setCreateModalOpen(true)
    });
  }

  const AddEntryValue = (values) => {

    const data = {
      "object_id": objectId,
      "type": values.type,
      "title": values.title,
      "workshop": values.workshop,
      "date_of_creation": values.date_of_creation,
      "time_of_creation": values.time_of_creation,
      "engine_hours": values.engine_hours,
      "vehicle_mileage": values.vehicle_mileage,
      "created_by": userDetail.id,
      "popup_notify": values.popupnotifi,
      "email_notify": values.Email,
      "fields": values.MhFieldList,
      "tasks": values.MhTaskList,
    }

    if (EditHsMode) {
      WScalling(baseApiUrl + updateEntry + Rowid, "put", data, userDetail.token).then((response) => {
        setAddentrydialog(false)
        const res = { ...response.data };
        res.cost = res.cost !== '' ? res.cost + ' €' : ''

        if (res.type === 'Change') {
          res.type = t('Lang.' + res.type);
        }
        if (res.type === 'Equipping') {
          res.type = t('Lang.' + res.type);
        }
        if (res.type === 'Inspection') {
          res.type = t('Lang.' + res.type);
        }
        if (res.type === 'Repair') {
          res.type = t('Lang.' + res.type);
        }
        if (res.type === 'Update') {
          res.type = t('Lang.' + res.type);
        }

        let updatedData = [...HistoryList];
        let objindx = updatedData.findIndex(o => o.id === Rowid);
        updatedData[objindx] = res
        setHistoryList(updatedData)
      });
    }
    else {
      WScalling(baseApiUrl + createEntry, "post", data, userDetail.token).then((response) => {
        //show data into Listing
        setAddentrydialog(false)
        const res = { ...response.data };
        res.cost = res.cost !== '' ? res.cost + ' €' : ''
        HistoryList.push(res)
        setHistoryList([...HistoryList])
      });
    }


    // // Validating Fields
    // const validation = validateAllEntry(EditHsMode ? FieldlList : MhFieldList, EditHsMode ? TaskList : MhTaskList)

    // if (!validation) {
    //   setaddentryfuc(true)
    // }
    // else {
    // }
  }

  const EditApicalling = (row) => {

    WScalling(baseApiUrl + EditApi + row.original.id, "get", data, userDetail.token).then((response) => {
      setRowid(row.original.id)

      if (response.data.tasks === undefined || response.data.tasks.length === 0) {
      }
      else {
        formMHistory.MhTaskList = response.data.tasks
      }

      if (response.data.fields === undefined || response.data.fields.length === 0) {
      }
      else {
        for (let i = 0; i < response.data.fields.length; i++) {
          if (response.data.fields[i].type === "checkbox") {
            if (response.data.fields[i].value === "") {
              response.data.fields[i].value = []
            }
            else {
              response.data.fields[i].value = response.data.fields[i].value.split(",")
            }
          }
        }
        formMHistory.MhFieldList = response.data.fields
      }

      formMHistory.type = response.data.type
      formMHistory.title = response.data.title
      formMHistory.workshop = response.data.workshop
      formMHistory.date_of_creation = response.data.date_of_creation
      formMHistory.time_of_creation = response.data.time_of_creation
      formMHistory.engine_hours = response.data.engine_hours
      formMHistory.vehicle_mileage = response.data.vehicle_mileage
      formMHistory.popupnotifi = response.data.popup_notify
      formMHistory.Email = response.data.email_notify

      setEditHsMode(true)
      setAddentrydialog(true)
    });
  }

  const AddObjectValue = (values) => {
    const data = {
      "name": values.name,
      "status": EditMode ? values.status : "1",
      "data": values.objFieldList,
      "company_id": userDetail.company_id,
    }

    if (EditMode) {
      WScalling(baseApiUrl + ObjUpdate + Rowid, "put", data, userDetail.token).then((response) => {
        //show data into Listing  
        setCreateModalOpen(false)
        let updatedData = [...objTableList];
        let objindx = updatedData.findIndex(o => o.id === Rowid);
        updatedData[objindx] = response.data
        setTableList(updatedData)
      });
    }
    else {
      WScalling(baseApiUrl + Createobj, "post", data, userDetail.token).then((response) => {
        //show data into Listing
        setCreateModalOpen(false)
        objTableList.push(response.data)
        checkmaxobj(objTableList.length)
        setTableList([...objTableList])
      });
    }
  }

  const SearchByType = (objval) => {
    searchObjectType = objval;
    FilterNotification()
  }

  const SearchByObjectName = (objval) => {
    searchObjectName = objval;
    FilterNotification()
  }

  const handleFocus = () => {
    if (!isTyping) {
      setIsTyping(true);
    }
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  const SearchByToggle = (checked) => {
    searchObjectExpire = checked;
    FilterNotification()
  }

  const FilterNotification = () => {
    filterList = filteredObjects;
    if (searchObjectType !== null) {
      filterList = filterList.filter(
        (type) => type.object_type === searchObjectType
      );

    }

    if (searchObjectName !== null) {
      filterList = filterList.filter((type) => {
        if (type.name.toLowerCase().includes(searchObjectName.toLowerCase())) {
          return true
        }
        else {
          return false
        }
      });

    }

    if (searchObjectExpire !== null) {
      setexpireobj(searchObjectExpire)

      if (searchObjectExpire) {
        filterList = filterList.filter((type) => {
          // Check if any key in the type object has the value '01.01.1970'
          for (let key in type) {
            if (type[key] === '01.01.1970') {
              return false;
            }
          }
          if (type.expired === undefined || type.expired === '' || type.past_date === '01.01.1970') {
            return false; 
          } else {
            return true;
          }
        });        
      }
      else {
      }
    }
    setTableList(filterList)
  }

  const ObjectTableList = () => {

    return <MaterialReactTable
      localization={{
        actions: t('Lang.Actions'),
        noRecordsToDisplay: t('Lang.No records to display'),
        noResultsFound: t('Lang.No results found'),
        of: t('Lang.of'),
        goToFirstPage: t('Lang.Go to first page'),
        goToLastPage: t('Lang.Go to last page'),
        goToNextPage: t('Lang.Go to next page'),
        goToPreviousPage: t('Lang.Go to previous page'),
      }}

      // Row Click OF Table
      muiTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          setLoading(true)
          setIsTyping(false)
          setShowMslist(true)
          // getEntryFieldList()
          MaintenenceHsList(row)
        },
        sx: {
          cursor: 'pointer', //you might want to change the cursor too when adding an onClick
        },
        hover: false
      })}


      muiTableBodyProps={{
        sx: {
          //stripe the rows, make odd rows a darker color
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#f5f5f5",
          },
        },
      }}


      muiTableBodyCellProps={{
        sx: {
          border: "0 solid #e0e0e0", //add a border between columns
        },
      }}

      defaultColumn={{
        minSize: 20, //allow columns to get smaller than default
      }}

      columns={objColumnList === [] ? null : objColumnList}
      data={objTableList}
      enableBottomToolbar={false}
      positionPagination={"top"}
      editingMode="modal" //default

      enableEditing={rolename ? false : true} // If Set False then action column hide.
      enableSorting={true}

      enableColumnFilters={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableHiding={false}
      enablePagination={true}

      positionActionsColumn="last"
      initialState={{
        density: "compact",
        showGlobalFilter: true,
        columnVisibility: { id: false, order: false },
      }}
      enableGlobalFilter={false}

      muiSearchTextFieldProps={{
        placeholder: "Search...",
      }}

      onPaginationChange={(state) => {
        setpagstate(true)
        setPagination(state)
      }
      }

      onSortingChange={setSorting}
      state={{ pagination, sorting }}

      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Tooltip arrow placement="left" title={t('Lang.Edit')}>
            <IconButton
              className="edit-btn p-0"
              onClick={(e) => {
                e.stopPropagation();
                setObjfuc(false)
                setRowid(row.original.id)
                getEditFieldList(row.original.id);
              }}>
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
              </svg>
              <span className="ms-1">{t('Lang.Edit')}</span>
            </IconButton>
          </Tooltip>
        </Box>
      )}

      options={{
        renderRowActionsColumnIndex: -1,
        tableLayout: "fixed",
      }}

      icons={{
        DragHandleIcon: () => null,
        MoreVertIcon: () => null
      }}

      renderTopToolbarCustomActions={() => (
        <>

          <div className="pt-lg-2 px-0 d-flex flex-wrap justify-content-xl-between justify-content-lg-start w-100 gap-lg-2 gap-2 pt-lg-2 pt-2 pt_20">

            <div className="d-flex flex-lg-nowrap md-grid-cols-2 flex-wrap gap-lg-3 gap-2 align-items-center box-1 order-lg-0 order-2">

              <div className="form_search w-md-50">
                <select
                  className="form-control select-dropdown"
                  id="type"
                  name="type"
                  value={SearchObjType}
                  type="text"
                  onChange={(e) => {

                    setSearchObjType(e.target.value)

                    if (e.target.value === 'Select object type') {
                      setTableList(filterObjList)
                    }
                    else {
                      SearchByType(e.target.value)
                    }
                  }}
                  placeholder="Select Type"
                  required
                >
                  <option disabled={true} value="">
                    {t('Lang.Select object type')}
                  </option>

                  {objectTypeList.map((e, key) => {
                    return <option key={key} value={e.option}>{t('Lang.' + e.option)}</option>;
                  })}
                </select>
              </div>


              <form className="form_search w-md-50">
                <input
                  value={SearchObjinput}
                  className="form-control mr-sm-2"
                  type="search"
                  autoFocus={isTyping}
                  onFocus={handleFocus}
                  // onBlur={handleBlur}
                  onChange={(e) => {
                    setSearchObjinput(e.target.value)
                    SearchByObjectName(e.target.value)
                  }}
                  aria-label="Search"
                  placeholder={t('Lang.Search object name..')}
                  style={{ borderColor: isTyping ? '#333' : '#F0F0F0' }}>
                </input>
                <svg viewBox="-2 -2 103 103"><path strokeWidth="3" d="M78.11,69.76c-1.21-1.12-2.69-1.5-3.29-.85s-2.1.27-3.32-.83L70.3,67a3.75,3.75,0,0,1-.64-4.56S78,49,78,39A39,39,0,1,0,39,78c9,0,21.17-6.67,21.17-6.67A4.51,4.51,0,0,1,65,71.9l1.61,1.46c1.22,1.11,1.82,2.44,1.34,3s.11,1.87,1.33,3L90.89,99.24a3,3,0,0,0,4.24-.17l4.74-5.14a3,3,0,0,0-.17-4.24ZM39,71A32,32,0,1,1,71,39,32,32,0,0,1,39,71Z" /></svg>
              </form>


              <div className="d-flex align-items-center">
                <h4 className="user my-lg-2 my-0">{t('Lang.Show only vehicles with expired elements')}</h4>

                <Switch
                  checked={expireobj}
                  name="status"
                  onChange={(event) => {
                    SearchByToggle(event.target.checked)
                  }}
                />
              </div>
            </div>


            <div className="gap-lg-3 gap-2 d-flex justify-content-end w-md-100 flex_wrap_420 box-2 order-lg-1 order-1">

              {
                rolename ? null :
                  objstatus ? null :
                    <Button
                      color="secondary"
                      className="add-new-field"
                      onClick={() => {
                        setObjfuc(false)
                        setEditMode(false);
                        SetObjectIcon('')
                        setupdatedData([])
                        addfieldsApi()
                      }}
                      variant="contained"
                    >
                      {t('Lang.Add new object')}
                    </Button>
              }

              <Button
                color="secondary"
                className="add-new-field"
                onClick={() => {
                  setSearchObjType('')
                  setSearchObjinput('')
                  setexpireobj(false)
                  setTableList(filterObjList)
                  setShowMslist(false)

                  searchObjectType = null;
                  searchObjectName = null;
                  searchObjectExpire = null;
                }}
                variant="contained"
              >
                {t('Lang.RESET')}
              </Button>
            </div>

          </div>

        </>

      )}
    />
  }

  const MaintenenceHsList = (row) => {

    const data = {
      "object_id": row.original.id
    }

    WScalling(baseApiUrl + HistoryListApi, "post", data, userDetail.token).then((response) => {

      setLoading(false)
      setHstitle(row.original.name)
      setObjectId(row.original.id)
      setHsSubtitle(row.original.make === null || row.original.make === undefined ? '' : row.original.model === null || row.original.model === undefined ? '' : row.original.make + " " + row.original.model)

      if (response.data !== []) {
        for (let i = 0; i < response.data.length; i++) {
          const field = response.data[i];

          if (response.data[i].type === 'Change') {
            field.type = t('Lang.' + field.type);
          }
          if (response.data[i].type === 'Equipping') {
            field.type = t('Lang.' + field.type);
          }
          if (response.data[i].type === 'Inspection') {
            field.type = t('Lang.' + field.type);
          }
          if (response.data[i].type === 'Repair') {
            field.type = t('Lang.' + field.type);
          }
          if (response.data[i].type === 'Update') {
            field.type = t('Lang.' + field.type);
          }
        }
        setHistoryList(response.data)
      }

    });
  }

  const MaintanceHistoryList = () => {
    const pagination = mhpagination
    const sorting = HSsorting

    return <MaterialReactTable
      localization={{
        actions: t('Lang.Actions'),
        noRecordsToDisplay: t('Lang.No records to display'),
        noResultsFound: t('Lang.No results found'),
        of: t('Lang.of'),
        goToFirstPage: t('Lang.Go to first page'),
        goToLastPage: t('Lang.Go to last page'),
        goToNextPage: t('Lang.Go to next page'),
        goToPreviousPage: t('Lang.Go to previous page'),
      }}

      muiTableBodyProps={{
        sx: {
          //stripe the rows, make odd rows a darker color
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#F5F5F5",
          },
        },
      }}

      muiTableBodyCellProps={{
        sx: {
          border: "0 solid #E0E0E0", //add a border between columns
        },
      }}

      // enableFilters={false}
      columns={HistoryColumns}
      data={HistoryList}

      positionPagination={"top"}
      enableBottomToolbar={false}
      editingMode="modal" //default
      enableEditing={rolename ? false : true}  // If Set False then action column hide.
      enableSorting={true}

      enableColumnFilters={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableHiding={false}
      enablePagination={true}
      // enableColumnActions={false}
      positionActionsColumn="last"


      onSortingChange={setHSSorting}

      onPaginationChange={(state) => {
        setmhpagstate(true)
        setMhPagination(state)
      }}

      state={{ pagination, sorting }}


      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Tooltip arrow placement="left" title={t('Lang.Edit')}>
            <IconButton
              className="edit-btn"
              onClick={() => {
                setaddentryfuc(false)
                if (addFields === [] || addFields.length === 0) {
                }
                else {
                  addFields = []

                }
                EditApicalling(row)
              }}>
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
              </svg>
              <span className="ms-1">{t('Lang.Edit')}</span>
            </IconButton>
          </Tooltip>
        </Box>
      )}

      options={{
        renderRowActionsColumnIndex: -1,
        tableLayout: "fixed",
      }}

      icons={{
        DragHandleIcon: () => null,
        MoreVertIcon: () => null
      }}

      renderTopToolbarCustomActions={() => (
        <>
          <div className="maintaine_history d-flex flex-wrap gap-2 align-items-center mb-sm-0 mb-2">
            <span>{Hstitle}</span>
            <h3>{HsSubtitle}</h3>
          </div>

          {
            rolename ? null :
              <Button
                color="secondary"
                className="add-new-field ms-0 ms-sm-auto"
                onClick={() => {
                  setaddentryfuc(false)
                  // clearallEntry()
                  getEntryFieldList2()
                }}
                variant="contained"
              >
                {t('Lang.ADD ENTRY')}
              </Button>
          }
        </>
      )}
    >
    </MaterialReactTable>

  }

  const getEntryFieldList2 = () => {


    WScalling(baseApiUrl + GetEntryField, "post", { 'company_id': userDetail.company_id }, userDetail.token).then((response) => {

      if (response.data.tasks === undefined || response.data.tasks.length === 0) { }
      else {
        formMHistory.MhTaskList = response.data.tasks
      }

      if (response.data.fields === undefined || response.data.fields.length === 0) {
      }
      else {
        formMHistory.MhFieldList = response.data.fields
      }

      formMHistory.type = ''
      formMHistory.title = ''
      formMHistory.workshop = ''
      formMHistory.date_of_creation = ''
      formMHistory.time_of_creation = ''
      formMHistory.engine_hours = ''
      formMHistory.vehicle_mileage = ''
      formMHistory.popupnotifi = '1'
      formMHistory.Email = '0'

      setEditHsMode(false)
      setAddentrydialog(true)
    });
  }

  const formatdate = (dates) => {
    if (typeof dates === 'string') {
      return dates.split('.')[2] + '-' + dates.split('.')[1] + '-' + dates.split('.')[0];
    }
    return dates; // Return the input if it's not a string
  }

  const formatDateEurope = (dateString) => {
    let [completeDate, completeTime] = dateString.split(' ');

    if (!completeTime) {
      const [year, month, day] = completeDate.split('-');
      return `${day}.${month}.${year}`;
    }

    let [year, month, day] = completeDate.split('-');
    let [hours, minutes, seconds] = completeTime.split(':');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;   
  };

  const Datecheck = (dateval) => {
    if (isNaN(dateval.get('date')) || isNaN(dateval.get('month')) || isNaN(dateval.get('year'))) {
      return null
    }
    else {
      return ("0" + dateval.get('date')).slice(-2) + '.' + ("0" + (dateval.get('month') + 1)).slice(-2) + '.' + dateval.get('year');
    }
  }

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const Options = [
    { value: 'Change', label: 'Lang.Change' },
    { value: 'Equipping', label: 'Lang.Equipping' },
    { value: 'Inspection', label: 'Lang.Inspection' },
    { value: 'Repair', label: 'Lang.Repair' },
    { value: 'Update', label: 'Lang.Update' },
  ];

  const sortedOptions = Options.sort((a, b) => {
    const labelA = t(a.label).toLowerCase();
    const labelB = t(b.label).toLowerCase();
    return labelA.localeCompare(labelB);
  });

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>

      <div className="col-sm p-3 min-vh-100 main-listing">

        <div className="position-relative pagination_top">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-3">
              <li className="breadcrumb-item active" aria-current="page">
                {" "}
                {t('Lang.Overview')}
              </li>
            </ol>
          </nav>

          <div className="configure-section setting-section col-sm p-0 overview_table">
            <ObjectTableList />
          </div>

        </div>

        {
          ShowMslist ?
            <div className="d-flex justify-content-center flex-wrap">
              {
                loading ?
                  <div className="custom-loader mt-4"></div>
                  :
                  <>
                    <nav aria-label="breadcrumb" className="d-block w-100">
                      <ol className="breadcrumb d-block mb-2">
                        <li className="breadcrumb-item active mb-sm-0" aria-current="page">
                          {" "}
                          {t('Lang.Object Entries')}
                        </li>
                      </ol>
                    </nav>

                    <div className="d-block w-100 configure-section setting-section col-sm p-0 maintenance_history">
                      <MaintanceHistoryList />
                    </div>
                  </>
              }
            </div>
            :
            null
        }

        {/* Add Object POPUP */}
        <Dialog open={createModalOpen} className="d-w-400 popup_gape">

          <div className="popup-header d-flex justify-content-between">
            <DialogTitle textAlign="center" className="p-3">
              {EditMode ? t('Lang.Edit object') : t('Lang.Add new object')}
            </DialogTitle>{" "}
            <Button onClick={() => setCreateModalOpen(false)}>
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>
            </Button>
          </div>
          <DialogContent>


            <Formik
              initialValues={forminitialval}
              onSubmit={AddObjectValue}
              validate={values => {

                let errors = {};

                if (values.name === '')
                  errors.name = t('Lang.This is a required field.')

                values.objFieldList.forEach((item, index) => {

                  if (!item.value || Array.isArray(item.value)) {
                    errors.objFieldList = errors.objFieldList || [];

                    if (item.type === 'checkbox' && item.is_required === '1' && (item.value === "" || item.value.length === 0)) {
                      errors.objFieldList[index] = errors.objFieldList[index] || {};
                      errors.objFieldList[index].value = t('Lang.This is a required field.');
                    }

                    if (item.is_required === '1' && item.value === '') {
                      errors.objFieldList[index] = errors.objFieldList[index] || {};
                      errors.objFieldList[index].value = t('Lang.This is a required field.');
                    }
                  }
                })

                if (Object.keys(errors).length === 0) {
                }
                else {
                  if ((errors.objFieldList === undefined || errors.objFieldList.length === 0) && values.name !== '') {
                    errors = {};
                  }
                }
                return errors;
              }}
            >

              {({ values, handleChange, setFieldValue, errors }) => (

                <Form className="add--fields">
                  <Stack
                    sx={{
                      width: "100%",
                      minWidth: { xs: "300px", sm: "360px", md: "400px" },
                      gap: "1.5rem",
                    }}
                  >


                    {/* Status */}
                    {
                      EditMode ? (
                        <div className="form-group row mx-0">
                          <label className="col-sm-5 px-0 col-form-label">{t('Lang.Status')}</label>
                          <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                            <div className="form-check form-switch text-sm-end mb-0 p-0">
                              <Switch
                                checked={values.status === '1' ? true : false}
                                name="status"
                                // value={true}
                                onChange={(event) => {
                                  setFieldValue(`status`, event.target.checked ? "1" : "0")
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}



                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Name')}</label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Field className="form-control" type="text" name="name" />
                        <ErrorMessage name="name" component="div" className="invalid-feedback d-block" />
                      </div>
                    </div>


                    {
                      values.objFieldList.map((element, index) => (
                        <>
                          {
                            element.type === 'last_update' ? null :

                              element.type === 'type' ?

                                <div className="form-group row mx-0">
                                  <label className="col-sm-5 px-0 col-form-label">
                                    {t('Lang.' + element.label)}
                                  </label>


                                  <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                                    {
                                      <div className="form-group mb-2">
                                        <div className="d-flex gap-2 flex-wrap">

                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="CAR"
                                              onChange={handleChange}
                                            />

                                            <span className="img-common car"></span>
                                          </div>

                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="TRUCK"
                                              onChange={handleChange}
                                            />

                                            <span className="img-common truck"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="BUS"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common bus"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="MOTORBIKE"
                                              onChange={handleChange}
                                            />

                                            <span className="img-common motorbike"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="TRAILER"
                                              onChange={handleChange}
                                            />

                                            <span className="img-common trailer"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="TRACTOR"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common tractor"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="BOAT"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common boat"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="FORKLIFT"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common forklift"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="TRAIN"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common train"></span>
                                          </div>


                                          <div className="radio-common">
                                            <Field
                                              name={`objFieldList.${index}.value`}
                                              type="radio"
                                              value="EXCAVATOR"
                                              onChange={handleChange}
                                            />
                                            <span className="img-common excavator"></span>
                                          </div>

                                        </div>

                                        <ErrorMessage className="invalid-feedback d-block" name={`objFieldList[${index}].value`} component="div" />

                                      </div>

                                    }
                                  </div>
                                </div>
                                :

                                element.type === 'checkbox' ?

                                  <div className="form-group row mx-0">
                                    <label className="col-lg-5 px-0 col-form-label">
                                      {element.label}
                                    </label>

                                    <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                      {element.values.map((e, indx) => <div className="check_box">
                                        <label className="col-form-label  d-flex gap-2 align-items-center">

                                          <Field
                                            className={Object.keys(errors).length === 0 ? errors : errors.objFieldList[index] !== undefined ? 'checkbox-comman validate-error' : 'checkbox-comman'}
                                            checked={values.objFieldList[index].value.includes(e.value)}
                                            name={`objFieldList.${index}.value`}
                                            type="checkbox"
                                            value={e.value}
                                            onChange={(event) => {

                                              const array = values.objFieldList[index].value === "" ? [] : values.objFieldList[index].value
                                              if (array.length === 0) {
                                                array.push(event.target.value)
                                              }
                                              else {
                                                if (array.includes(event.target.value)) {
                                                  const index = array.indexOf(event.target.value);
                                                  if (index > -1) { // only splice array when item is found
                                                    array.splice(index, 1); // 2nd parameter means remove one item only
                                                  }
                                                }
                                                else {
                                                  array.push(event.target.value)
                                                }
                                              }

                                              setFieldValue(`objFieldList.${index}.value`, array)
                                            }}

                                          />
                                          {e.value}
                                        </label>
                                      </div>)}
                                    </div>
                                  </div>
                                  :

                                  element.type === 'dropdown' ?
                                    <div className="form-group row mx-0">
                                      <label className="col-sm-5 px-0 col-form-label">
                                        {element.label}
                                      </label>

                                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">

                                        <select
                                          className="form-control select-dropdown"
                                          name={`objFieldList.${index}.value`}
                                          value={(values.objFieldList[index].value === '' || values.objFieldList[index].value === null) ? '' : values.objFieldList[index].value}
                                          type="text"
                                          placeholder="Select Type"
                                          onChange={(event) => {
                                            setFieldValue(`objFieldList.${index}.value`, event.target.value)
                                          }}>
                                          <option disabled={true} value=""> {t('Lang.Select')} {element.label} </option>
                                          {element.values.map((e, key) => {
                                            return <option key={key} value={e.value}>{e.value}</option>;
                                          })}
                                        </select>

                                        <ErrorMessage className="invalid-feedback d-block" name={`objFieldList[${index}].value`} component="div" />

                                        {/* <span className="invalid-feedback d-block">{addObjfuc ? entryValonField(addFields, index, element.is_required) : ''}</span> */}
                                      </div>
                                    </div>

                                    :

                                    element.type === 'yes_no' ?
                                    <div className="form-group row mx-0">
                                      <label className="col-sm-5 px-0 col-form-label">
                                        {element.label}
                                      </label>

                                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">

                                        <select
                                          className="form-control select-dropdown"
                                          name={`objFieldList.${index}.value`}
                                          value={(values.objFieldList[index].value === '' || values.objFieldList[index].value === null) ? '' : values.objFieldList[index].value}
                                          type="text"
                                          placeholder="Select"
                                          onChange={(event) => {
                                            setFieldValue(`objFieldList.${index}.value`, event.target.value)
                                          }}>
                                          <option disabled={true} value=""> {t('Lang.Select')} </option>
                                          {element.values.map((e, key) => {
                                            return <option key={key} value={e.value}>{t('Lang.' + e.value)}</option>;
                                          })}
                                        </select>

                                        <ErrorMessage className="invalid-feedback d-block" name={`objFieldList[${index}].value`} component="div" />

                                        {/* <span className="invalid-feedback d-block">{addObjfuc ? entryValonField(addFields, index, element.is_required) : ''}</span> */}
                                      </div>
                                    </div>

                                    :

                                    element.type === 'date' ?

                                      <div className="form-group row mx-0">
                                        <label className="col-sm-5 px-0 col-form-label">
                                          {element.label}
                                        </label>

                                        <div className="col-sm-7 pe-0 ps-sm-2 ps-0 date_picker datem">

                                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker']}>
                                              <DatePicker
                                                onChange={(newValue) => {
                                                  if (Datecheck(newValue) !== null) {
                                                    setFieldValue(`objFieldList.${index}.value`, Datecheck(newValue))
                                                  }
                                                }}
                                                name={`objFieldList.${index}.value`}
                                                // slots={{
                                                //   openPickerIcon: AccessibleIcon,
                                                // }}
                                                slotProps={{ textField: { size: 'small', placeholder: t('Lang.Select date'), label: '' } }}
                                                format='DD.MM.YYYY'
                                                value={(values.objFieldList[index].value === "" || values.objFieldList[index].value === '01.01.1970') ? null : dayjs(formatdate(values.objFieldList[index].value))}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>

                                          <ErrorMessage className="invalid-feedback d-block" name={`objFieldList[${index}].value`} component="div" />

                                        </div>
                                      </div>

                                      :

                                      <div key={element.id} className="form-group row mx-0">
                                        <label className="col-sm-5 px-0 col-form-label">
                                          {element.label}
                                        </label>

                                        <div key={element.id} className="col-sm-7 pe-0 ps-sm-2 ps-0">
                                          <Field
                                            name={`objFieldList.${index}.value`}
                                            className="form-control"
                                            type={element.type}
                                            value={element.value}
                                            placeholder={element.label}
                                            onChange={handleChange}
                                            min={element.type === 'number' ? "0" : null}
                                            onKeyDown={element.type === 'number' ? blockInvalidChar : null}
                                          />
                                          <ErrorMessage className="invalid-feedback d-block" name={`objFieldList[${index}].value`} component="div" />
                                        </div>
                                      </div>
                          }
                        </>
                      ))
                    }

                    <button
                      variant="contained"
                      color="secondary"
                      className="add-new-field w-100"
                      type="submit">

                      {EditMode ? t('Lang.Update') : t('Lang.Save')}

                    </button>
                  </Stack>
                </Form >
              )}
            </Formik>

          </DialogContent>
        </Dialog>



        {/* Maintanance History LIst POPUP */}
        <Dialog className="entry_popup" open={Addentrydialog}>

          <div className="popup-header d-flex justify-content-between">
            <DialogTitle textAlign="center" className="p-3">
              {EditHsMode ? t('Lang.Edit entry') : t('Lang.Add new entry')}
            </DialogTitle>{" "}

            <Button onClick={() => setAddentrydialog(false)}>
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>
            </Button>
          </div>


          <DialogContent>

            <Formik
              initialValues={formMHistory}
              onSubmit={AddEntryValue}
              validate={values => {

                let errors = {};
                const txterror = t('Lang.This is a required field.')


                if (values.type === '')
                  errors.type = txterror
                if (values.title === '')
                  errors.title = txterror
                if (values.workshop === '')
                  errors.workshop = txterror
                if (values.date_of_creation === '')
                  errors.date_of_creation = txterror
                if (values.time_of_creation === '')
                  errors.time_of_creation = txterror


                values.MhFieldList.forEach((item, index) => {
                  errors.MhFieldList = errors.MhFieldList || [];

                  if (item.type === 'checkbox' && item.is_required === '1' && (item.value === "" || item.value.length === 0)) {
                    errors.MhFieldList[index] = errors.MhFieldList[index] || {};
                    errors.MhFieldList[index].value = t('Lang.This is a required field.');
                  }

                  if (item.is_required === '1' && item.value === "") {
                    errors.MhFieldList[index] = errors.MhFieldList[index] || {};
                    errors.MhFieldList[index].value = t('Lang.This is a required field.');
                  }
                })



                values.MhTaskList.forEach((item, index) => {
                  errors.MhTaskList = errors.MhTaskList || [];

                  if (item.type === 'checkbox' && item.is_required === '1' && (item.value === "" || item.value === '0')) {
                    errors.MhTaskList[index] = errors.MhTaskList[index] || {};
                    errors.MhTaskList[index].value = t('Lang.This is a required field.');
                  }

                  if (item.is_required === '1' && item.value === "") {
                    errors.MhTaskList[index] = errors.MhTaskList[index] || {};
                    errors.MhTaskList[index].value = t('Lang.This is a required field.');
                  }
                })

                if (Object.keys(errors).length === 0) {
                }

                else {
                  if ((errors.MhFieldList === undefined || errors.MhFieldList.length === 0) && (errors.MhTaskList === undefined || errors.MhTaskList.length === 0) &&
                    values.type !== '' && values.title !== '' && values.workshop !== '' &&
                    values.date_of_creation !== '' && values.time_of_creation) {
                    errors = {};
                  }
                }
                return errors;
              }}
            >

              {({ values, handleChange, setFieldValue, errors }) => (

                <Form className="add--fields add--entry position-relative">

                  <div className="row mx-0">

                    <div className="col-md-6 pe-md-4 pe-3">

                      <div className="form-group row pb-2 disabled_filed">
                        <label className="col-sm-5 px-0 col-form-label fs-6 f-600">{t('Lang.Object')} </label>
                        <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Object"
                            name="name"
                            value={Hstitle}
                            required
                            disabled
                          />
                        </div>
                      </div>


                      {/* Type */}
                      <div className="form-group row pb-2">
                        <label className="col-lg-5 px-0 col-form-label">{t('Lang.Type')}</label>

                        <div className="control col-lg-7 pe-0 ps-lg-2 ps-0">
                          <select
                            // disabled={EditMode}
                            className="form-control select-dropdown"
                            name="type"
                            value={values.type}
                            type="text"
                            onChange={(event) => {
                              setFieldValue(`type`, event.target.value)
                            }}
                            placeholder="Select Type">

                            <option disabled={true} value="">
                              {t('Lang.Select any type')}
                            </option>

                            {sortedOptions.map((option) => (
                              <option key={option.value} value={option.value} >
                                {t(option.label)}
                              </option>
                            ))}
                          </select>

                          <ErrorMessage name="type" component="div" className="invalid-feedback d-block" />
                        </div>
                      </div>


                      {/* Title */}
                      <div className="form-group row pb-2">
                        <label className="col-lg-5 px-0 col-form-label">{t('Lang.Title')}</label>
                        <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                          <Field
                            name={`title`}
                            className="form-control"
                            type="text"
                            // value={element.value}
                            placeholder={t('Lang.Enter title')}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="title" component="div" className="invalid-feedback d-block" />
                        </div>
                      </div>


                      {/* Workshop */}
                      <div className="form-group row pb-2">
                        <label className="col-lg-5 px-0 col-form-label">{t('Lang.Workshop')}</label>
                        <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                          <Field
                            name={`workshop`}
                            className="form-control"
                            type="text"
                            // value={element.value}
                            placeholder={t('Lang.Enter workshop')}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="workshop" component="div" className="invalid-feedback d-block" />
                        </div>
                      </div>


                      <div className="form-group row pb-2">
                        <label className="col-sm-5 px-0 col-form-label fs-6 f-600 py-3">{t('Lang.Insert data')}</label>
                      </div>



                      {/* Date_of_creation */}
                      <div className="form-group row pb-2">
                        <label className="col-lg-5 px-0 col-form-label">{t('Lang.Date of creation')}</label>
                        <div className="col-lg-7 pe-0 ps-lg-2 ps-0 date_picker">

                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                onChange={(newValue) => {
                                  if (Datecheck(newValue) !== null) {
                                    setFieldValue(`date_of_creation`, Datecheck(newValue))
                                  }
                                }}
                                name={`date_of_creation`}
                                // slots={{
                                //   openPickerIcon: AccessibleIcon,
                                // }}
                                slotProps={{ textField: { size: 'small', placeholder: t('Lang.Select date'), label: '' } }}
                                format='DD.MM.YYYY'
                                value={(values.date_of_creation === "" || values.date_of_creation === '01.01.1970') ? null : dayjs(formatdate(values.date_of_creation))}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <ErrorMessage name="date_of_creation" component="div" className="invalid-feedback d-block" />

                        </div>
                      </div>



                      {/* Time_of_creation */}
                      <div className="form-group row pb-2 clock_icon">
                        <label className="col-lg-5 px-0 col-form-label">{t('Lang.Time of creation')}</label>
                        <div className="col-lg-7 pe-0 ps-lg-2 ps-0">

                          <Field
                            name={`time_of_creation`}
                            className="form-control position-relative"
                            type="time"
                            // value={element.value}
                            placeholder="Enter Time of creation"
                            onChange={handleChange}
                          />
                          <ErrorMessage name="time_of_creation" component="div" className="invalid-feedback d-block" />
                        </div>
                      </div>



                      <>
                        <div className="form-group row">
                          <label className="col-lg-5 px-0 col-form-label fs-6 f-600 py-3">{t('Lang.Fields')}</label>
                        </div>


                        {
                          values.MhFieldList.length === 0 || values.MhFieldList === undefined ? null :
                            <>
                              {
                                values.MhFieldList.map((element, index) => (
                                  <>
                                    {
                                      element.type === 'date' ?
                                        <div className="form-group row pb-2">
                                          <label className="col-lg-5 px-0 col-form-label">
                                            {t('Lang.Next')} {element.label}
                                          </label>

                                          <div className="col-lg-7 pe-0 ps-lg-2 ps-0 date_picker">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                              <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                  className="form-control"
                                                  onChange={(newValue) => {
                                                    if (!newValue) {
                                                      // case where the date is cleared
                                                      setFieldValue(`MhFieldList.${index}.value`, '');
                                                    } else if (Datecheck(newValue) !== null) {
                                                      setFieldValue(`MhFieldList.${index}.value`, Datecheck(newValue));
                                                    } 
                                                    else {
                                                      // setFieldValue(`MhFieldList.${index}.value`, '');
                                                      setFieldValue(`MhFieldList.${index}.value`, dayjs(newValue).format('DD.MM.YYYY'));
                                                    }
                                                  }}
                                                  placeholderText={t('Lang.Select date')}
                                                  name={`MhFieldList.${index}.value`}
                                                  slotProps={{ textField: { size: 'small', placeholder: t('Lang.Select date'), label: '' } }}
                                                  format='DD.MM.YYYY'
                                                  value={(values.MhFieldList[index].value === "" || values.MhFieldList[index].value === '01.01.1970' || values.MhFieldList[index].value === null) ? null : dayjs(formatdate(values.MhFieldList[index].value))}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>

                                            <ErrorMessage name={`MhFieldList[${index}].value`} component="div" className="invalid-feedback d-block" />

                                            {/* <span className="invalid-feedback d-block">{addentryfuc ? (EditHsMode ? entryValonField(FieldlList, index, element.is_required) : entryValonField(MhFieldList, index, element.is_required)) : ''}</span> */}
                                          </div>
                                        </div>

                                        :

                                        element.type === 'dropdown' ?
                                          <div className="form-group row pb-2">
                                            <label className="col-lg-5 px-0 col-form-label">
                                              {element.label}
                                            </label>

                                            <div className="col-lg-7 pe-0 ps-lg-2 ps-0">

                                              <select
                                                className="form-control select-dropdown"
                                                name={`MhFieldList.${index}.value`}
                                                value={(values.MhFieldList[index].value === "" || values.MhFieldList[index].value === null) ? '' : values.MhFieldList[index].value}
                                                type="text"
                                                onChange={(event) => {
                                                  setFieldValue(`MhFieldList.${index}.value`, event.target.value)
                                                }}
                                                placeholder="Select Type"

                                              >
                                                <option disabled={true} value="">{t('Lang.Select')} {element.label} </option>
                                                {element.values.map((e, key) => {
                                                  return <option key={key} value={e.value}>{e.value}</option>;
                                                })}
                                              </select>

                                              <ErrorMessage name={`MhFieldList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                            </div>
                                          </div>

                                          :
                                          
                                          element.type === 'yes_no' ?
                                          <div className="form-group row pb-2">
                                            <label className="col-lg-5 px-0 col-form-label">
                                              {element.label}
                                            </label>

                                            <div className="col-lg-7 pe-0 ps-lg-2 ps-0">

                                              <select
                                                className="form-control select-dropdown"
                                                name={`MhFieldList.${index}.value`}
                                                value={(values.MhFieldList[index].value === "" || values.MhFieldList[index].value === null) ? '' : values.MhFieldList[index].value}
                                                type="text"
                                                onChange={(event) => {
                                                  setFieldValue(`MhFieldList.${index}.value`, event.target.value)
                                                }}
                                                placeholder="Select Type"

                                              >
                                                <option disabled={true} value="">{t('Lang.Select')} </option>
                                                {element.values.map((e, key) => {
                                                  return <option key={key} value={e.value}>{t('Lang.' + e.value)}</option>;
                                                })}
                                              </select>

                                              <ErrorMessage name={`MhFieldList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                            </div>
                                          </div>

                                          :

                                          element.type === 'checkbox' ?
                                            <div className="form-group row pb-2">
                                              <label className="col-lg-5 px-0 col-form-label">
                                                {element.label}
                                              </label>

                                              <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                                {element.values.map((e, indx) => <div className="check_box">
                                                  <label className="col-form-label  d-flex gap-2 align-items-center">
                                                    <Field
                                                      className={Object.keys(errors).length === 0 ? errors : errors.MhFieldList[index] !== undefined ? 'checkbox-comman validate-error' : 'checkbox-comman'}
                                                      checked={values.MhFieldList[index].value.includes(e.value)}
                                                      name={`MhFieldList.${index}.value`}
                                                      type="checkbox"
                                                      value={e.value}
                                                      onChange={(event) => {
                                                        const array = values.MhFieldList[index].value === "" ? [] : values.MhFieldList[index].value

                                                        if (array.length === 0) {
                                                          array.push(event.target.value)
                                                        }
                                                        else {
                                                          if (array.includes(event.target.value)) {
                                                            const index = array.indexOf(event.target.value);
                                                            if (index > -1) { // only splice array when item is found
                                                              array.splice(index, 1); // 2nd parameter means remove one item only
                                                            }
                                                          }
                                                          else {
                                                            array.push(event.target.value)
                                                          }
                                                        }
                                                        setFieldValue(`MhFieldList.${index}.value`, array)
                                                      }}
                                                    />
                                                    {e.value}
                                                  </label>
                                                </div>)}
                                              </div>
                                            </div>
                                            :

                                            <div className="form-group row pb-2">
                                              <label className="col-lg-5 px-0 col-form-label">
                                                {element.label}
                                              </label>

                                              <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                                <Field
                                                  name={`MhFieldList.${index}.value`}
                                                  className="form-control"
                                                  type={element.type}
                                                  // value={(values.MhFieldList[index].value === '' || null) ? "" : values.MhFieldList[index].value}
                                                  placeholder={element.label}
                                                  onChange={handleChange}
                                                />
                                                <ErrorMessage name={`MhFieldList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                              </div>
                                            </div>
                                    }
                                  </>
                                ))
                              }
                            </>
                        }
                      </>
                    </div>



                    {/* MhTaskList */}
                    <div className="col-md-6 ps-md-4 ps-0 pe-md-1 pe-0 d-flex flex-column">
                      <div className="mb-auto">
                        {
                          values.MhTaskList.length === 0 || values.MhTaskList === undefined ? null :
                            <>
                              <div className="pb-0">
                                <label className="col-sm-5 px-0 col-form-label fs-6 f-600">{t('Lang.Tasks')}</label>
                              </div>
                              {

                                values.MhTaskList.map((element, index) => (

                                  <>
                                    {
                                      element.type === 'dropdown' ?

                                        <div className="form-group row pb-2">
                                          <label className="col-lg-5 px-0 col-form-label">
                                            {element.label}
                                          </label>

                                          <div className="col-lg-7 pe-0 ps-lg-2 ps-0">

                                            <select
                                              className="form-control select-dropdown"
                                              name={`MhTaskList.${index}.value`}
                                              value={(values.MhTaskList[index].value === "" || values.MhTaskList[index].value === null) ? '' : values.MhTaskList[index].value}
                                              type="text"
                                              onChange={(event) => {
                                                setFieldValue(`MhTaskList.${index}.value`, event.target.value)
                                              }}
                                              placeholder="Select Type"
                                              required
                                            >
                                              <option disabled={true} value="">{t('Lang.Select')} {element.label} </option>
                                              {element.values.map((e, key) => {
                                                return <option key={key} value={e.value}>{e.value}</option>;
                                              })}
                                            </select>

                                            <ErrorMessage name={`MhTaskList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>
                                        :

                                        element.type === 'yes_no' ?

                                        <div className="form-group row pb-2">
                                          <label className="col-lg-5 px-0 col-form-label">
                                            {element.label}
                                          </label>

                                          <div className="col-lg-7 pe-0 ps-lg-2 ps-0">

                                            <select
                                              className="form-control select-dropdown"
                                              name={`MhTaskList.${index}.value`}
                                              value={(values.MhTaskList[index].value === "" || values.MhTaskList[index].value === null) ? '' : values.MhTaskList[index].value}
                                              type="text"
                                              onChange={(event) => {
                                                setFieldValue(`MhTaskList.${index}.value`, event.target.value)
                                              }}
                                              placeholder="Select Type"
                                              required
                                            >
                                              <option disabled={true} value="">{t('Lang.Select')}</option>
                                              {element.values.map((e, key) => {
                                                return <option key={key} value={e.value}>{t('Lang.' + e.value)}</option>;
                                              })}
                                            </select>

                                            <ErrorMessage name={`MhTaskList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>
                                        
                                        :
                                        
                                        element.type === 'checkbox' ?
                                          <div className="check_box">
                                            <label className="col-form-label d-flex pb-2 gap-2 align-items-center">
                                              <Field
                                                className={Object.keys(errors).length === 0 ? errors : errors.MhTaskList[index] !== undefined ? 'checkbox-comman validate-error' : 'checkbox-comman'}
                                                checked={values.MhTaskList[index].value === '1' ? true : false}
                                                name={`MhTaskList.${index}.value`}
                                                type="checkbox"
                                                value={element.label}
                                                onChange={(event) => {
                                                  setFieldValue(`MhTaskList.${index}.value`, event.target.checked ? '1' : '0')
                                                }}
                                              />
                                              {element.label}
                                            </label>
                                          </div>
                                          :

                                          element.type === 'cost' ?
                                            <div className="form-group row pb-2 mx-0">
                                              <label className="col-lg-5 px-0 col-form-label">
                                                {element.label}
                                              </label>

                                              <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                                <div className="input_number">

                                                  <Field
                                                    name={`MhTaskList.${index}.value`}
                                                    className="form-control"
                                                    type="number"
                                                    // value={element.value}
                                                    placeholder={element.placeholder}
                                                    onChange={handleChange}
                                                  />
                                                  <ErrorMessage name={`MhTaskList[${index}].value`} component="div" className="invalid-feedback d-block" />

                                                  {/* <span className="invalid-feedback d-block">{addentryfuc ? (EditHsMode ? entryValonField(TaskList, index, element.is_required) : entryValonField(MhTaskList, index, element.is_required)) : ''}</span> */}
                                                  <span className="text_position">{element.unit}</span>
                                                </div>
                                              </div>
                                            </div>

                                            :

                                            element.type === 'textarea' ?
                                              <div className="form-group row pb-2 mx-0">
                                                <label className="col-lg-5 px-0 col-form-label">
                                                  {element.label}
                                                </label>

                                                <Field
                                                  component="textarea" rows="3"
                                                  name={`MhTaskList.${index}.value`}
                                                  className="form-control"
                                                  // value={element.value}
                                                  placeholder={element.placeholder}
                                                  onChange={handleChange}
                                                />
                                                <ErrorMessage name={`MhTaskList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                              </div>

                                              :

                                              <div className="form-group row pb-2 mx-0">
                                                <label className="col-lg-5 px-0 col-form-label">
                                                  {element.label}
                                                </label>

                                                <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                                  <Field
                                                    name={`MhTaskList.${index}.value`}
                                                    className="form-control"
                                                    type={element.type}
                                                    // value={(values.MhTaskList[index].value === '' || null) ? "" : values.MhTaskList[index].value}
                                                    placeholder={element.placeholder}
                                                    onChange={handleChange}
                                                  />
                                                  <ErrorMessage name={`MhTaskList[${index}].value`} component="div" className="invalid-feedback d-block" />
                                                </div>
                                              </div>
                                    }
                                  </>
                                ))}
                            </>
                        }



                        {/* Popup Notifications */}
                        <div className="check_box">
                          <label className="col-lg-12 px-0 col-form-label fs-6 f-600 py-3">{t('Lang.Select notification type')}</label>

                          <div>
                            <label className="col-form-label d-flex gap-2 align-items-center disabled_filed">
                              <Field
                                disabled={true}
                                className="checkbox-comman"
                                // checked={values.MhFieldList[index].value.includes(e.value)}
                                name={`popupnotifi`}
                                type="checkbox"
                              // value={e.value}
                              />
                              {t('Lang.Popup notification')}
                            </label>
                          </div>



                          {/* Email */}
                          <div>
                            <label className="col-form-label  d-flex gap-2 align-items-center">
                              <input
                                // value={EditHsMode ? FieldlList[index].value : MhFieldList[index].value}
                                checked={values.Email === '1' ? true : false}
                                className="checkbox-comman"
                                type="checkbox"
                                name={`Email`}
                                onChange={(e) => {
                                  setFieldValue(`Email`, e.target.checked ? '1' : '0')
                                }}
                              />
                              {t('Lang.E-Mail')}
                            </label>
                          </div>

                        </div>
                      </div>



                      <div className="text-md-end text-center">
                        <button
                          variant="contained"
                          color="secondary"
                          className="add-new-field"
                          type="submit">
                          {
                            t('Lang.Save')
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}

            </Formik>
          </DialogContent>
        </Dialog>


      </div>
    </>
  );
}

export default Home;